import React from "react"
import SEO from "../components/global/Seo"
import Layout from "../components/layout/Layout"

import ContactSection from "../components/home/ContactSection"

export default function Kontakt() {
  return (
    <Layout breadcrumbs={[{ title: "Kontakt", url: "/kontakt" }]}>
      <SEO title="Kontakt" />
      <ContactSection />
    </Layout>
  )
}
