import React from "react"
import ContactForm from "../forms/ContactForm"
import Smartphone from "../../../static/images/icons/smartphone.svg"
import Mail from "../../../static/images/icons/mail.svg"
import Pin from "../../../static/images/icons/pin.svg"
import contactData from "../../../content/pages/contact.yml"

export default function ContactSection({ isHome = false }) {
  return (
    <section
      id="contact"
      className={`contact-section section-padding ${isHome ? "gray-bg" : ""}`}
    >
      <div className="container">
        {isHome && (
          <div className="contact-header">
            <h2 className="section-title">Kontakt</h2>
          </div>
        )}
        <div className="row mb-5">
          <div className="col-lg-6">
            <div className="contact-box contact-info">
              <div className="border-icon">
                <Pin />
              </div>
              <div className="contact-info__body">
                <h3>Adres</h3>
                <p>{contactData.address}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-box contact-info">
                  <div className="border-icon">
                    <Mail />
                  </div>
                  <div className="contact-info__body">
                    <h3>E-mail</h3>
                    <a href={`mailto:${contactData.email}`}>
                      {contactData.email}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-box contact-info">
                  <div className="border-icon">
                    <Smartphone />
                  </div>
                  <div className="contact-info__body">
                    <h3>Telefon</h3>
                    <p>{contactData.phone}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="contact-box map-container">
              <iframe
                title="gmap"
                loading="lazy"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4652.598020877754!2d18.58032076663953!3d54.33402301023415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd741167357e97%3A0x9ffc801b5af75726!2sMariana%20Ko%C5%82odzieja%2057%2F29%2C%2080-180%20Gda%C5%84sk!5e0!3m2!1spl!2spl!4v1609795062818!5m2!1spl!2spl"
                height="490"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="contact-box">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
