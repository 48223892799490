import { useStaticQuery } from "gatsby"
import React, { useState } from "react"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function ContactForm() {
  const [klient, setKlient] = useState()
  const [email, setEmail] = useState()
  const [telefon, setTelefon] = useState()
  const [wiadomosc, setWiadomosc] = useState()
  const [isFormSend, setIsFormSend] = useState(false)
  const [sendError, setSendError] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    const model = { klient, email, telefon, wiadomosc }
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": event.target.getAttribute("name"),
        ...model,
      }),
    })
      .then(() => setIsFormSend(true))
      .catch(() => setSendError(true))
  }

  return (
    <div className="form-contact contact_form">
      <form
        name="Contact Form"
        method="POST"
        data-netlify="true"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="Contact Form" />
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <input
                type="text"
                name="klient"
                className="form-control"
                placeholder="Imię i nazwisko"
                onChange={e => setKlient(e.target.value)}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="E-mail"
                onChange={e => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <input
                type="text"
                name="telefon"
                className="form-control"
                placeholder="Telefon"
                onChange={e => setTelefon(e.target.value)}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <textarea
                type="text"
                name="wiadomosc"
                className="form-control"
                placeholder="Wiadomość"
                rows="3"
                onChange={e => setWiadomosc(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="col-12">
            <input
              type="submit"
              value="Wyślij"
              className="button button-button-square button-primary"
            />

            <label htmlFor="rodo">
              <input
                id="rodo"
                name="rodo"
                type="checkbox"
                required
              />{" "}
              Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z ustawą o
              ochronie danych osobowych w związku z wysłaniem zapytania przez
              formularz kontaktowy. Podanie danych jest dobrowolne, ale
              niezbędne do przetworzenia zapytania. Zostałem/łam
              poinformowany/a, że przysługuje mi prawo dostępu do swoich danych,
              możliwości ich poprawienia, żądania zaprzestania ich
              przetwarzania. Administratorem danych osobowych jest Biuro
              Rachunkowe Michalina Łabęcka.
            </label>
            {isFormSend && (
              <div className="success-message">Wiadomość wysłana poprawnie</div>
            )}
            {sendError && (
              <div className="error-message">
                Wystąpił błąd podczas wysyłania wiadomości
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}
